import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import HeaderTopSectionForOther from "./compontes/header/HeaderTopSectionForOther";
import HeaderTopSectionForRoot from "./compontes/header/HeaderTopSectionForRoot";
import HeaderBottamSection from "./compontes/header/HeaderBottamSection";
import MobileAdsHeader from "./pages/MobileAdsHeader";

import { ToastContainer } from "react-toastify";
import Router from "./routes";
import { ReactInternetSpeedMeter } from "react-internet-meter";
import socketIO from "socket.io-client";
import { RxCross2 } from "react-icons/rx";
import "react-toastify/dist/ReactToastify.css";
import "react-internet-meter/dist/index.css";
import "./App.css";

export default function MainApp() {
  // const [wifiSpeed, setwifiSpeed] = useState(0)
  // const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isLoading, setLoading] = useState(true);
  function someRequest() { //Simulates a request; makes a "promise" that'll run for 2.5 seconds
  return new Promise(resolve => setTimeout(() => resolve(), 2500));
} 
useEffect(() => {
  someRequest().then(() => {
    const loaderElement = document.querySelector(".loader-container");
    if (loaderElement) {
      loaderElement.remove();
      setLoading(!isLoading);
    }
  });
});
  const params = useLocation();
  const [OSName, setonName] = useState("other");
  const [Openmodule, setOpenmodule] = useState(true);
  var connectionOptions = {
    transports: ["websocket"],
    autoConnect: true,
  };
  var socket = socketIO.connect(
    `https://chat.soundchatradio.com:8000/`,
    connectionOptions
  );
  // useEffect(() => {
  //   function onlineHandler() {
  //     setIsOnline(true);
  //   }
  //   function offlineHandler() {
  //     setIsOnline(false);
  //   }
  //   window.addEventListener("online", onlineHandler);
  //   window.addEventListener("offline", offlineHandler);
  //   return () => {
  //     window.removeEventListener("online", onlineHandler);
  //     window.removeEventListener("offline", offlineHandler);
  //   };
  // }, []);
  useEffect(() => {
    if (navigator.appVersion.indexOf("Win") != -1) setonName("Windows");
    if (navigator.appVersion.indexOf("Mac") != -1) setonName("MacOS");
    if (navigator.appVersion.indexOf("X11") != -1) setonName("UNIX");
    if (navigator.appVersion.indexOf("Linux") != -1) setonName("Linux");
    if (navigator.appVersion.indexOf("Android") != -1) setonName("Android");
    if (navigator.appVersion.indexOf("iPhone") != -1) setonName("iPhone");
  }, [navigator.appVersion]);
  const crosfunbutton = () => {
    if (Openmodule === true) {
      setOpenmodule(false);
    }
  };
  const [audioplayerstatus, setaudioplayerstatus] = useState("pause");
  const [videoplayerstatus, setvideoplayerstatus] = useState("pause");

  function callbackheader(status) {
    setaudioplayerstatus(status);
  }
  function vedioplayercallback(status) {
    setvideoplayerstatus(status);
  }
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  if (isLoading) {
    return null;
  }
  return (
    
    <>
      {screenSize.width < 768 ? (
        <></>
      ) : (
        <>
          {/* {(params.pathname==='/Login' || params.pathname==='/Register' || params.pathname==='/Forgotpassword')? <></>:(params.pathname!=='/')?<HeaderTopSectionForOther />:<><HeaderTopSectionForRoot/></>} */}
          {params.pathname === "/Login" ||
          params.pathname === "/Register" ||
          params.pathname === "/Forgotpassword" ? (
            <></>
          ) : (
            <>
              <HeaderTopSectionForRoot />
            </>
          )}

          {/* {(params.pathname!=='/')?<HeaderTopSectionForOther />:<><HeaderTopSectionForRoot/></> } */}
        </>
      )}

      {params.pathname === "/Login" ||
      params.pathname === "/Register" ||
      params.pathname === "/Forgotpassword" || params.pathname === "/Chatting" ? (
        <></>
      ) : params.pathname !== "/" ? (
        <HeaderBottamSection />
      ) : (
        <></>
      )}
      {params.pathname === "/Login" ||
      params.pathname === "/Register" ||
      params.pathname === "/Forgotpassword" ||  
      params.pathname === "/Chatting" ? (
        <></>
      ) : params.pathname !== "/" ? (
        <MobileAdsHeader />
      ) : (
        <></>
      )}

      {OSName === "Android" ? (
        <>
          {Openmodule ? (
            <>
              <div className="add-desktop-div">
                <div className="modile-downdload-link-box">
                  <p
                    className="cros-but-download"
                    onClick={() => {
                      crosfunbutton();
                    }}
                  >
                    <RxCross2 />
                  </p>
                  <div>
                    <h6 className="title-ad-download">
                      
                      Download the SoundChatRadio App today.
                    </h6>
                    <p className="title-download-small">
                      The Caribbean #1 Radio & TV Network
                    </p>
                  </div>
                  <button
                    className="plya-store-link"
                    onClick={() => {
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.soundchatradio.broadlink",

                        "_blank"
                      );
                    }}
                  >
                    Get App
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : OSName === "iPhone" ? (
        <>
          {Openmodule ? (
            <>
              <div className="add-desktop-div">
                <div className="modile-downdload-link-box">
                  <p
                    className="cros-but-download"
                    onClick={() => {
                      crosfunbutton();
                    }}
                  >
                    <RxCross2 />
                  </p>
                  <div>
                    <h6 className="title-ad-download">
                      {" "}
                      Download the SoundChatRadio App today.
                    </h6>
                    <p className="title-download-small">
                      The Caribbean #1 Radio & TV Network
                    </p>
                  </div>
                  <button
                    className="plya-store-link"
                    onClick={() => {
                      window.open(
                        "https://apps.apple.com/us/app/sound-chat-radio/id1585082754",
                        "_blank"
                      );
                    }}
                  >
                    Get App
                  </button>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      <Router props={socket} />
      <ToastContainer />
      {/* {(isOnline) ? <><ReactInternetSpeedMeter
        txtSubHeading="Internet is too slow"
        outputType="alert"
        customClassName={"networkSpeedClass"}
        txtMainHeading="Opps..."
        pingInterval={4000} // milliseconds 
        thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
        threshold={1}
        imageUrl="https://app.soundchatradio.com/soundradiobackend/images/emoticon/e14cb035e314ed3f032a54a56d41e509"
        downloadSize="800000"  //bytes
        callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
      /></> : <><ReactInternetSpeedMeter
        txtSubHeading="You are offline. Please check your internet connection."
        outputType="alert"
        customClassName={"networkSpeedClass"}
        txtMainHeading="Opps..."
        pingInterval={4000} // milliseconds 
        thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
        threshold={0}
        imageUrl="https://app.soundchatradio.com/soundradiobackend/images/emoticon/e14cb035e314ed3f032a54a56d41e509"
        downloadSize="800000"  //bytes
        callbackFunctionOnNetworkTest={(speed) => setwifiSpeed(speed)}
      /></>} */}
    </>
  );
}
