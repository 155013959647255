import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import donation from "../../asstes/images/bt2.png";
import { BsCart, BsCollectionPlayFill, BsImages , BsDisplayFill } from "react-icons/bs";
import { MdEmojiEvents, MdPermContactCalendar } from "react-icons/md";
import { FaRegUser, FaHome, FaShopify, FaDonate, FaHeadphones} from "react-icons/fa";
import { FiMenu, FiSearch, FiAirplay  } from "react-icons/fi";
import { AiOutlineClose, AiTwotoneAudio } from "react-icons/ai";
import axios from "axios";
import logo from "../../asstes/images/logo.png";
import Searchpage from "../../pages/Searchpage";
function HeaderBottamSection() {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("accessToken");
  const userName = localStorage.getItem("soundchatusername");
  const id = localStorage.getItem("id");
  const jsonItems = JSON.parse(localStorage.getItem("card"));
  const [ListingCartPop, setListingCartPop] = useState([]);
  const time = moment().tz("America/Detroit").format("HH:mm");
  const [totalamount, settotalamount] = useState(0);
  const [quantityData, setquantityData] = useState(0);
  const [lgShow1, setLgShow1] = useState(false);
  const [count, setcount] = useState(0);
  const [data, setData] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [dropdownState, setDropdownState] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [userNameState, setuserNameState] = useState("");
  let dataid = JSON.stringify({
    user_id: id,
  });
  useEffect(() => {
    if (token && userName) {
      const header = {
        "x-access-token": JSON.parse(localStorage.getItem("accessToken")),
        "Content-Type": "application/json",
      };
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_BASE_URL}/getprofile`,
        headers: header,
        data: dataid,
      };
      axios
        .request(config)
        .then((response) => {
          if (response.data.status === 400) {
            setuserNameState("");
          } else {
            setuserNameState(response.data.data.username);
          }
        })
        .catch((error) => {
          setuserNameState("");
        });
    } else {
      setuserNameState("");
    }
  }, [token]);
  const showSidebar = () => setSidebar(!sidebar);
  const handleDropdownClick = () => {
    setDropdownState(!dropdownState);
  };
  const handleSetDropdownValue = (value) => {
    setDropdownValue(value);
    setDropdownState(!dropdownState);
  };
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  useEffect(() => {
    let isCancelled = false;
    const infiLoop = async () => {
      await timeout(10);
      if (!isCancelled) {
        if (jsonItems) {
          setcount(jsonItems.cart_items.length);
          setListingCartPop(jsonItems.cart_items);
        } else {
          setcount(0);
        }
      }
    };
    infiLoop();
    return () => {
      isCancelled = true;
    };
  }, [jsonItems]);

  useEffect(() => {
    var total = 0;
    var quantity = 0;
    for (var i = 0; i < ListingCartPop.length; i++) {
      quantity = quantity + parseInt(ListingCartPop[i].quantity);
      total =
        total +
        parseInt(ListingCartPop[i].quantity) *
        parseFloat(ListingCartPop[i].Price);
    }
    settotalamount(total);
    setquantityData(quantity);
  }, [ListingCartPop]);

  const showToastMessage = (data) => {
    toast.success(data, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  function logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("id");
    showToastMessage("Logout Sccessfully");
    navigate("/", { state: { logoutstatus: "true" } });
  }
  
  return (
    <>
      <div className="header-bottam">
        <Navbar expand="lg" className="desktop">
          <Container fluid>
            <Nav className="me-auto my-2 my-lg-0">
                  <NavLink to="/" className="header-menu">
                    Home
                  </NavLink>
                  <NavLink to="/Listen" className="header-menu">
                    Listen
                  </NavLink>
                  <NavLink to="/Watch" className="header-menu">
                    Watch
                  </NavLink>
                  <NavLink to="/MediaGallery" className="header-menu">
                    Media Gallery
                  </NavLink>
                  <NavLink to="/Interview" className="header-menu">
                    Interviews
                  </NavLink>
                  <NavLink to="/RecapTV" className="header-menu">
                    Recap TV
                  </NavLink>
                  <NavLink to="/Podcast" className="header-menu">
                    Podcast
                  </NavLink>
                  <NavLink to="/ppvevent" className="header-menu">
                    PPV & Event
                  </NavLink>
                  <NavLink to="/Shop" className="header-menu">
                    Shop
                  </NavLink>
                  {/* <NavLink to="/Download" className="header-menu">
                    Downloads
                    </NavLink> */}
                  <NavLink to="/Contact" className="header-menu">
                    Contact Us
                  </NavLink>
            </Nav>

            <div className="section-div-right">
              <small className="bottom-pera header-menu-1">
                {parseInt(time) < 12
                  ? `Hi ${userNameState}, Good Morning!`
                  : parseInt(time) >= 12 && parseInt(time) <= 18
                    ? `Hi ${userNameState}, Good Afternoon!`
                    : `Hi ${userNameState}, Good Evening!`}
              </small>

              {token && id ? (
                <div className={`dropdown`}>
                  <button
                    onClick={handleDropdownClick}
                    className="dropdown-btn"
                  >
                    {dropdownValue === "" ? <FaRegUser /> : dropdownValue}
                  </button>
                  <div
                    className={`dropdown-items ${dropdownState ? "isVisible" : "isHidden"
                      }`}
                  >
                    <div className="dropdown-item">
                      <div className="dropdown__link">
                        <NavLink to={"/Myprofile"} onClick={handleDropdownClick}>My Profile</NavLink>
                      </div>
                    </div>
                    <div className="dropdown-item">
                      <div className="dropdown__link">
                        <NavLink to="/Order" onClick={handleDropdownClick}>My Orders</NavLink>
                      </div>
                    </div>
                    <div className="dropdown-item">
                      <div
                        className="dropdown__link"
                        onClick={() => {
                          logout();
                        }}
                      >
                        {(token === null && id === null) ||
                          (token === undefined && id == undefined) ||
                          (token === "" && id === "") ? (
                          <></>
                        ) : (
                          <>Logout</>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <NavLink className="header-menu-1" to="/Login">
                  <FaRegUser />
                </NavLink>
              )}
              <NavLink className="header-menu-1" onClick={() => {
                setLgShow1(true);
              }}>
                <FiSearch />
              </NavLink>
              <NavLink
                to="/Donation"
                key={"/Donation"}
                className="header-menu-1 donation-div-menu"
              >
                <img src={donation} alt="" />
              </NavLink>


              {location.pathname === "/Cart" ||
                location.pathname === "/Checkout" ? (
                <></>
              ) : (
                <>
                  <NavLink
                    className="header-menu-1"
                    onClick={() => setData(!data)}
                  >
                    <BsCart />
                    <h1 className="header-coutong">{count}</h1>
                  </NavLink>
                </>
              )}

              {data ? (
                <>
                  <div className="card-cat-checkout-man">
                    {ListingCartPop.length === 0 ? (
                      <h5 className="cart-empty">Cart Is Empty</h5>
                    ) : (
                      <div className="total-shping-cart">
                        <div className="scroll-box">
                          <table>
                            <tbody>
                              {ListingCartPop.map((element) => {
                                return (
                                  <tr
                                    className="main-header-checkout"
                                    key={element.id}
                                  >
                                    <td>
                                      <figure className="itemside align-items-center">
                                        <div className="aside">
                                          <img
                                            className="img-sm"
                                            src={
                                              `${process.env.REACT_APP_SHOPS_IMGURL}${element.image}`
                                            }
                                            alt="Img..."
                                          />
                                        </div>

                                        <figcaption className="info">
                                          <p
                                            className="title text-dark"
                                            data-abc="true"
                                          >
                                            {element.title}
                                          </p>
                                         
                                          <p className="text-muted small">
                                          {(element.size === null && element.color === null) ? (<></>) : (<>   <span>Size : {element.size} &nbsp;</span>
                                          <span>Color : {element.color} </span>   <br/></>)}
                                            {/* Size : {element.size}
                                            &nbsp;
                                            Color : {element.color}  */}
                                          
                                            Quantity : {element.quantity}&nbsp;
                                            Price : $
                                            {parseFloat(element.Price).toFixed(
                                              2
                                            )} &nbsp;
                                            SubTotal : $
                                            {parseFloat(
                                              parseFloat(
                                                element.quantity
                                              ).toFixed(2) *
                                              parseFloat(
                                                element.Price
                                              ).toFixed(2)
                                            ).toFixed(2)}
                                          </p>
                                        </figcaption>
                                      </figure>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr className="main-header-checkout">
                                <td className="main-header-card-tcet">Total</td>
                                <td className="text-right total-new-tt">
                                  ${parseFloat(totalamount).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mt-4">
                          <div className="countinew-shpnig">
                            <NavLink
                              to="/Cart"
                              className="countinew-empty ceconu"
                              onClick={() => setData(!data)}
                            >
                              Cart
                            </NavLink>
                          </div>
                          <div className="countinew-shpnig">
                            {token === undefined ||
                              token === null ||
                              token === "" ? (
                              <>
                                <NavLink
                                  to="/Login"
                                  className="countinew-empty ceconu-1 check-not-login"
                                  
                                >
                                  Login To Checkout
                                </NavLink>
                              </>
                            ) : (
                              <>
                                <NavLink
                                  to="/Checkout"
                                  className="countinew-empty ceconu-1"
                                  onClick={() => setData(!data)}
                                >
                                  Checkout
                                </NavLink>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : null}

            </div>
          </Container>
        </Navbar>
        <div className="header-mobile-box-outer">
          <div className="mobile">
            <div className="navbar-header-menu-mobile">
              <NavLink className="menu-bars">
                <FiMenu onClick={showSidebar} />
              </NavLink>
              <div className="header-logo-mobile">
                <img src={logo} alt="" />
              </div>
            </div>
            <nav className={sidebar ? "nav-menu active" : "nav-menu"}>

            {/* <div className="navbar-toggle">
                  <NavLink className="menu-bars">
                    <AiOutlineClose />
                  </NavLink>
                </div>
                <div className="lst-mobile-headerleft-side-bar">
                  <NavLink  className="heading-mobile-menu-bar">
                  {parseInt(time) < 12
                  ? `Hi ${userNameState}, Good Morning!`
                  : parseInt(time) >= 12 && parseInt(time) <= 18
                    ? `Hi ${userNameState}, Good Afternoon!`
                    : `Hi ${userNameState}, Good Evening!`}
                  </NavLink>
                </div> */}
              <ul
                className="nav-menu-items header-mobile-list"
                onClick={showSidebar}
              >
                <li className="navbar-toggle">
                  <NavLink className="sider-bar-title-colos">
                     <p><AiOutlineClose className="cros-side-bar" /></p>             
                    <p className="mobile-good-massage">{parseInt(time) < 12
                  ? `Hi ${userNameState}, Good Morning!`
                  : parseInt(time) >= 12 && parseInt(time) <= 18
                    ? `Hi ${userNameState}, Good Afternoon!`
                    : `Hi ${userNameState}, Good Evening!`}
                    </p>
                  </NavLink>
                </li>
                {/* <li className="lst-mobile-headerleft-side-bar">
                  <NavLink  className="heading-mobile-menu-bar">
                  {parseInt(time) < 12
                  ? `Hi ${userNameState}, Good Morning!`
                  : parseInt(time) >= 12 && parseInt(time) <= 18
                    ? `Hi ${userNameState}, Good Afternoon!`
                    : `Hi ${userNameState}, Good Evening!`}
                  </NavLink>
                </li> */}
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <FaHome/> </span>
                  <NavLink to="/" className="header-menu">
                    Home
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <FaHeadphones/> </span>
                  <NavLink to="/Listen" className="header-menu">
                  Listen
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <BsCollectionPlayFill/> </span> 
                  <NavLink to="/Watch" className="header-menu">
                 Watch 
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <BsImages/> </span> 
                  <NavLink to="/MediaGallery" className="header-menu">
                 Media Gallery 
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <AiTwotoneAudio/> </span> 
                  <NavLink to="/Interview" className="header-menu">
                  Interviews 
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <BsDisplayFill/> </span> 
                  <NavLink to="/RecapTV" className="header-menu">
                  Recap TV 
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <FaHeadphones/> </span>
                  <NavLink to="/Podcast" className="header-menu">
                Podcast 
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <MdEmojiEvents/> </span>
                  <NavLink to="/ppvevent" className="header-menu">
                  PPV & Event
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <FaShopify/> </span> 
                  <NavLink to="/Shop" className="header-menu">
                   Shop
                  </NavLink>
                </li>
                {/* <li className="lst-mobile-headerleft-side-bar">
              <NavLink to="/Download" className="header-menu">
                    Downloads
              </NavLink>
              </li> */}
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <MdPermContactCalendar/> </span>
                  <NavLink to="/Contact" className="header-menu">
                   Contact Us
                  </NavLink>
                </li>
                <li className="lst-mobile-headerleft-side-bar">
                <span className="sider-mobile-navber" > <FaDonate/> </span>
                  <NavLink to="/Donation"
                    key={"/Donation"} className="header-menu">
                     Donation 
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
          <div className="hesder-good-day">
            {token && id ? (
              <div className={`dropdown dropdown-mobile`}>
                <button onClick={handleDropdownClick} className="dropdown-btn header-menu-2">
                  {dropdownValue === "" ? <FaRegUser /> : dropdownValue}
                </button>
                <div
                  className={`dropdown-items mobile-dropdown-items  ${dropdownState ? "isVisible" : "isHidden"
                    }`}
                >
                  <div className="dropdown-item">
                    <div className="dropdown__link">
                      <NavLink to={"/Myprofile"}  onClick={handleDropdownClick} >My Profile</NavLink>
                    </div>
                  </div>

                  <div className="dropdown-item">
                    <div className="dropdown__link">
                      <NavLink to="/Order" onClick={handleDropdownClick}>My Orders</NavLink>
                    </div>
                  </div>
                  <div className="dropdown-item">
                    <div
                      className="dropdown__link"
                      onClick={() => {
                        logout();
                      }}
                    >
                      {(token === null && id === null) ||
                        (token === undefined && id == undefined) ||
                        (token === "" && id === "") ? (
                        <></>
                      ) : (
                        <>Logout</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NavLink className="header-menu-2" to="/Login">
                <FaRegUser />
              </NavLink>
            )}
            <NavLink className="header-menu-2" onClick={() => {
              setLgShow1(true);
            }}>
              <FiSearch />
            </NavLink>
            
            <div className="user-profile-new">
              <p
                className="header-menu-2 new--menu1"
                onClick={() => setData(!data)}
              >
                <BsCart />
                <h1 className="header-coutong">{count}</h1>
              </p>
              {data ? (
                <>
                  <div className="card-cat-checkout-man ">
                    {ListingCartPop.length === 0 ? (
                      <h5 className="cart-empty">Cart Is Empty</h5>
                    ) : (
                      <div className="total-shping-cart">
                        <div className="scroll-box">
                          <table>
                            <tbody>
                              {ListingCartPop.map((element, index) => {
                                return (
                                  <tr className="main-header-checkout" key={index}>
                                    <td>
                                      <figure className="itemside align-items-center">
                                        <div className="aside">
                                          <img
                                            className="img-sm"
                                            src={
                                              `${process.env.REACT_APP_SHOPS_IMGURL}${element.image}`
                                            }
                                            alt="Img..."
                                          />
                                        </div>
                                        <figcaption className="info">
                                          <p
                                            className="title text-dark"
                                            data-abc="true"
                                          >
                                            {element.title}
                                          </p>
                                          
                                          <p className="text-muted small">
                                          {(element.size === null && element.color === null) ? (<></>) : (<>   <span>Size : {element.size} &nbsp;</span>
                                          <span>Color : {element.color} </span>   <br/></>)}
                                          {/* Size : {element.size},
                                          &nbsp;
                                            Color : {element.color}  */}
                                        
                                            Quantity : {element.quantity} &nbsp;
                                            Price : $
                                            {parseFloat(element.Price).toFixed(2)} &nbsp;
                                            SubTotal : $
                                            {parseFloat(
                                              parseFloat(element.quantity).toFixed(
                                                2
                                              ) *
                                              parseFloat(element.Price).toFixed(2)
                                            ).toFixed(2)}
                                          </p>
                                        </figcaption>
                                      </figure>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr className="main-header-checkout">
                                <td className="main-header-card-tcet">Total</td>
                                <td className="text-right total-new-tt">
                                  ${parseFloat(totalamount).toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="mobile-cart-box mt-4">
                          <div className="countinew-shpnig">
                            <NavLink
                              to="/Cart"
                              className="countinew-empty ceconu"
                              onClick={() => setData(!data)}
                            >
                              Cart
                            </NavLink>
                          </div>
                          <div className="countinew-shpnig">
                            {token === undefined ||
                              token === null ||
                              token === "" ? (
                              <>
                                <NavLink
                                  to="/Login"
                                  className="countinew-empty ceconu-1 check-not-login"
                                >
                                  Login To Checkout
                                </NavLink>
                              </>
                            ) : (
                              <>
                                <NavLink
                                  to="/Checkout"
                                  className="countinew-empty ceconu-1"
                                  onClick={() => setData(!data)}
                                >
                                  Checkout
                                </NavLink>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : null}
            </div> 
          </div>
        </div>
      </div>
      <Searchpage
        show={lgShow1}
        close={() => setLgShow1(false)}
      />
    </>
  );
}
export default HeaderBottamSection;
