import React, { Suspense } from "react"
import { useRoutes, Navigate } from "react-router-dom"
import "swiper/css"
import "swiper/css/pagination"
import "bootstrap/dist/css/bootstrap.min.css"
import "react-image-lightbox/style.css"
import "./App.css"
import "../src/asstes/main.css"
// const App = React.lazy(() => import("../src/compontes/container/App"))
const App = React.lazy(() => import("../src/compontes/container/App"))
const Iframeaudio = React.lazy(() => import("./pages/Iframeaudio"))
const Donationifarm = React.lazy(() => import("./pages/Donationifarm"))
const Podcast = React.lazy(() => import("./pages/Podcast"))
const Shop = React.lazy(() => import("./pages/Shop"))
const PpvEvent = React.lazy(() => import("./pages/PpvEvent"))
const Myprofile = React.lazy(() => import("./pages/Myprofile"))
const ProductDetails = React.lazy(() => import("./compontes/shop/ProductDetails"))
const Stories = React.lazy(() => import("../src/compontes/morestories/Stories"))
const RecapTV = React.lazy(() => import("../src/pages/RecapTV"))
const Interview = React.lazy(() => import("./pages/Interview"))
// const RecapTVShowsVideo = React.lazy(() => import("./compontes/RecapVideoSection/latestrecaptvshows/RecapTVShowsVideo"))
const MediaGallery = React.lazy(() => import("./pages/MediaGallery"))
const Listen = React.lazy(() => import("./pages/Listen"))
const Viewstory = React.lazy(() => import("../src/compontes/morestories/Viewstory"))
// const RecentViewStory = React.lazy(() => import("../src/compontes/morestories/RecentViewStory"))
// const MoreViewStory = React.lazy(() => import("../src/compontes/morestories/MoreViewStory"))
const Contact = React.lazy(() => import("./pages/Contact"))
const PaymentStatus = React.lazy(() => import("./pages/PaymentStatus"))
const PpveventPaymentStatus = React.lazy(() => import("./pages/PpveventPaymentStatus"))
const InterviewsMoreVideos = React.lazy(() => import("./compontes/InterviewVideoSection/InterviewsMoreVideos"))
const Watchinterview = React.lazy(() => import("./compontes/InterviewVideoPages/Watchinterview"))
const Watch = React.lazy(() => import("./pages/Watch"))
const Login = React.lazy(() => import("./pages/Login"))
const Forgotpassword = React.lazy(() => import("./pages/Forgotpassword"))
const Cart = React.lazy(() => import("./pages/Cart"))
const Checkout = React.lazy(() => import("./pages/Checkout"))
// const RecapViewMoreVideo = React.lazy(() => import("./compontes/viewmorepages/RecapViewMoreVideo"))
const Chatting = React.lazy(() => import("./pages/Chatting"))
const Download = React.lazy(() => import("./pages/Download"))
const Changepassword = React.lazy(() => import("./pages/Changepassword"))
const Order = React.lazy(() => import("./pages/Order"))
const Orderdetails = React.lazy(() => import("./pages/Orderdetails"))
const Payment = React.lazy(() => import("./pages/Payment"))
const PpveventPayment = React.lazy(() => import("./pages/PpveventPayment"))
const Shippingedit = React.lazy(() => import("./pages/Shippingedit"))
const InterviewRecap = React.lazy(() => import("./pages/Search-Interview&Recap"))
const Register = React.lazy(() => import('./pages/Register'))
const Eventbooking = React.lazy(() => import('./pages/Eventbooking'))

export default function Router(props) {
  const token = localStorage.getItem("accessToken")
  return useRoutes([
    {
      path: "/", element: <Suspense>
        <App props={props}/>
      </Suspense>
    },
    {
      path: "/RecapTV", element: <Suspense
      >
        <RecapTV />
      </Suspense>
    },
    // {
    //   path: "/RecapTVShowsVideo", element: <Suspense
    //   >
    //     <RecapTVShowsVideo />
    //   </Suspense>
    // },
    // {
    //   path: "/RecapViewMoreVideo", element: <Suspense
    //   >
    //     <RecapViewMoreVideo />
    //   </Suspense>
    // },
    {
      path: "/Shop", element: <Suspense
      >
        <Shop />
      </Suspense>
    },
    {
      path: "/ProductDetails/:prodoctId", element: <Suspense
      >
        <ProductDetails />
      </Suspense>
    },
    {
      path: "/Interview", element: <Suspense
      >
        <Interview />
      </Suspense>
    },
    {
      path: "/Podcast", element: <Suspense
      >
        <Podcast />
      </Suspense>
    },
    {
      path: "/MediaGallery", element: <Suspense
      >
        <MediaGallery />
      </Suspense>
    },
    {
      path: "/Listen", element: <Suspense
      >
        <Listen />
      </Suspense>
    },
    {
      path: "/Contact", element: <Suspense
      >
        <Contact />
      </Suspense>
    },
    {
      path: "/InterviewsMoreVideos", element: <Suspense
      >
        <InterviewsMoreVideos />
      </Suspense>
    },
    {
      path: "/Watch", element: <Suspense
      >
        <Watch />
      </Suspense>
    },
    {
      path: "/Login", element: (token) ? <><Navigate to="/" /></> : <Suspense
      >
        <Login />
      </Suspense>
    },
    {
      path: "/Cart", element: <Suspense
      >
        <Cart />
      </Suspense>
    },
    {
      path: "/Checkout", element: <Suspense
      >
        <Checkout />
      </Suspense>
    },
    {
      path: "/Register", element: (token) ? <><Navigate to="/" /></> : <Suspense
      >
        <Register />
      </Suspense>
    },
    {
      path: "/Watchinterview", element: <Suspense
      >
        <Watchinterview />
      </Suspense>
    },
    {
      path: "/Stories", element: <Suspense
      >
        <Stories />
      </Suspense>
    },
    {
      path: "/Viewstory", element: <Suspense
      >
        <Viewstory />
      </Suspense>
    },
    // {
    //   path: "/RecentViewStory", element: <Suspense
    //   >
    //     <RecentViewStory />
    //   </Suspense>
    // },
    // {
    //   path: "/MoreViewStory", element: <Suspense
    //   >
    //     <MoreViewStory />
    //   </Suspense>
    // },
    {
      path: "/Forgotpassword", element: (token) ? <><Navigate to="/" /></> : <Suspense
      >
        <Forgotpassword />
      </Suspense>
    },
    {
      path: "/PodcastList", element: <Suspense
      >
        <Iframeaudio />
      </Suspense>
    },
    {
      path: "/Donation", element: <Suspense
      >
        <Donationifarm />
      </Suspense>
    },
    {
      path: "/Chatting", element: <Suspense
      >
        <Chatting  props={props}/>
      </Suspense>
    },
    {
      path: "/Download", element: <Suspense
      >
        <Download />
      </Suspense>
    },
    {
      path: "/Changepassword", element: <Suspense
      >
        <Changepassword />
      </Suspense>
    },
    {
      path: "/Order", element: <Suspense
      >
        <Order />
      </Suspense>
    },
    {
      path: "/Myprofile", element: <Suspense
      >
        <Myprofile />
      </Suspense>
    },
    {
      path: "/Orderdetails/:orderId", element: <Suspense
      >
        <Orderdetails />
      </Suspense>
    },
    {
      path: "/Shippingedit/ShippingeditId", element: <Suspense
      >
        <Shippingedit />
      </Suspense>
    },
    {
      path: "/PaymentStatus", element: <Suspense
      >
        <PaymentStatus />
      </Suspense>
    },
    {
      path: "/PpveventPaymentStatus", element: <Suspense
      >
        <PpveventPaymentStatus />
      </Suspense>
    },
    {
      path: "/ppvevent", element: <Suspense>
        <PpvEvent />
      </Suspense>
    },
    {
      path: "/Payment/:IntentId", element: <Suspense>
        <Payment />
      </Suspense>
    },
    {
      path: "/PpveventPayment/:IntentId", element: <Suspense>
        <PpveventPayment />
      </Suspense>
    },
    
    {
      path: "/Search-Interview-Recap/:searchType/:searchContent", element: <Suspense>
        <InterviewRecap />
      </Suspense>
    },
    {
      path: "/Eventbooking", element: <Suspense>
        <Eventbooking />
      </Suspense>
    },

  ])
}
